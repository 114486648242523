#aboutPoems * {
	margin: 3px 0;
	color: #333;

}
#aboutPoems dt {
	font-weight: bold;
}
#aboutPoems dd {
	margin: 0 0 0 50px;
}
.about-wrapper {
	width: 85%;
	margin: 0 auto;
	position: relative;
	.contact {
		dd {
			font-weight: bold;
		}
	}
}

@media (min-width: 1024px) {
	.about-wrapper {
		width: max-content;
		margin: 0 auto;
		position: relative;
	}
}