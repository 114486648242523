.pattern-maker {
	> div {
		display: flex;
		justify-content: center;
		flex-direction: column;
		canvas {
			&.over {
				cursor: pointer;
			}
		}
		> div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			> button {
				font-size: 15px;
				margin: 10px;
				padding: 2px 10px;
				border-radius: 5px;
				border: 1px solid rgba(0,0,0,0.5);
			}
		}
	}
	
}
@media (min-width: 1024px ) {
	.pattern-maker {
		> div {
			flex-direction: row;
		}
		
	}
}