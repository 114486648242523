#bam-logo {
	width: 100px;
	.bam-logo-cls-1 {
  fill: #f0f;
}

.bam-logo-cls-1, .bam-logo-cls-2 {
  stroke: #231f20;
  stroke-miterlimit: 10;
}

.bam-logo-cls-2 {
  fill: #231f20;
}
}

