.tab-shell {
	.tab-container {
		display: flex;
		width: 100%;
		li {
			width: 1fr;
			text-align: center;
			cursor: pointer;
			border: 1px solid rgba($color: #000000, $alpha: 0.25);
			&.active {
				border-bottom: none;
			}
		}

	}
}