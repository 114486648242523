.landing-page-internal-box {
	position: relative;
	margin: 0 auto;
	padding: 50px 0;
	h2, p {
		margin-bottom: 10px;
	}
	ul {
		margin-left: 25%;
		position: relative;
		z-index: 2;
		li {
			margin: 5px 0;
			a {
				font-weight: bold;
				cursor: pointer;
			}
		}
	}
	.landing-animation {
		position: absolute;
		border-radius: 50%;
		width: 500px;
		height: 500px;
		top: -200px;
		left: -250px;
		z-index: -1;
		canvas {
			position: relative;
			z-index: -1;
		}
	}
}

@media screen and (min-width: 768px) {
	.landing-page-internal-box {
		width:max-content;
	}
}