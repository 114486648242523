 :root {
	--light-grey: rgba(0,0,0,0.25);
	--mobile-breakpoint: 768px;
	--left-right-margin: 20px;
 }
 * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
 }
 ul {
	list-style-type: none;
 }
 .hide {
	display: none;
 }
 .show {
	display: default;
 }

 button {
	cursor: pointer;
 }

 a, a:visited {
	color: #333;
 }


