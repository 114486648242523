* {
    padding: 0;
    margin: 0;
}
#canvas-div {
    margin: 0 auto;
}
#crystal-ball-unifier {
    width: 100%;
	min-height: 100vh;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #181145;
    text-align: center;

	canvas {
		width: 100%; 
		height: 100%;
		max-width: 750px;
		max-height: 699px;
		display: block;
		margin: 0 auto;
	}

	#scroll-cover {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: black;
		opacity: 0.05;
	}
}
#crystal-ball-unifier img{
    display:block;
    margin: 0 auto;
    width: 80%;
    max-width: 475px;
}

