.svg-content {
	width: 90%;
	position: relative;
	z-index: 0;
	margin: 20px auto;
	background: rgba($color: #000000, $alpha: 0.5);
	.loading {
		position: absolute;
		width: 100%;
		margin: 30px auto;
		font-weight: bold;
		font-size: 24px;
		color: white;
		z-index: 1;
		text-align: center;
	}
	iframe {
		width: 100%;
		height: 100vh;
		position: relative;
		z-index: 2;
		&.loaded {
			background-color: white;
		}
	}
}