$nav-height: 50px;
$mobile-breakpoint: 1024px;

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  height: $nav-height;
  border-bottom: 2px solid var(--light-grey);
  h1 {
	position: relative;
	z-index: 103;
	margin-left: 20px;
	a {
		text-decoration: none;
	}
  }
  .menu-screen {
	background-color: white;
	position: absolute;
	top:0;
	left: 0;
	z-index: 101;
	width: 100vw;
	height: 50px;
	border-bottom: 2px solid var(--light-grey);
  }
  > ul {
		display: flex;
		position: absolute;
		z-index: 100;
		top: $nav-height;
		flex-direction: column;
		transition: top 1s ease-in-out;
		top: 50px;
		> li {
			width: 100vw;
			text-align: center;
			cursor: pointer;
			padding: 5px 0;
			border-bottom: 1px solid var(--light-grey);
			background-color: rgba($color: #FFFFFF, $alpha: 0.85);
		}
	}
  &.collapsed {
	> ul {
		top: -150px;
	}
	> .hamburger {
		> span:nth-child(1) {
			top: -5px;
		}
		> span:nth-child(3) {
			top: 5px;
		}
	  }
  }
  > svg {
	position: relative;
	z-index: 31;
	height: 50px;
  }
  > .hamburger {
	cursor: pointer;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-right: 20px;
	height:50px;
	position: relative;
	z-index: 104;
	> span {
		position: relative;
		width: 50px;
		display: block;
		border-bottom: 2px solid black;
		transition: bottom 0.25s linear, top 0.25s linear;
		&:nth-child(1) {
			top:0;
		}
		&:nth-child(3) {
			top:0;
		}

	}
  }
  #submenu {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    div {
      display: none;
      border-bottom: 1px solid var(--light-grey);
      width: 100%;
      &.show {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
}

@media (min-width: $mobile-breakpoint) {
  nav {
    // background-color: red;
    ul {
      flex-direction: row;
	  position: static;
	  list-style-type: none;
	  z-index: 400;
	  li {
		border: none;
		width: auto;
		margin: 0 20px;
		cursor: pointer;
	  }
    }
	.hamburger {
		display: none;
	}
  }
}
