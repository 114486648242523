#geometric-puzzle-cont {
  position: relative;
  #geometric-puzzle-button-cont {
    display: flex;
    justify-content: space-around;
    position: relative;
    z-index: 901;
    button {
      padding: 10px 15px;
      margin-top: 20px;
      font-size: 20px;
    }
  }
  canvas {
    position: absolute;
    top: 0;
    z-index: 900;
  }
}
