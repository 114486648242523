.svg-video-iframe-shell {

		position: relative;
		padding-bottom: 56.25%;
		 height: 0;
		 width: 80%;
		 margin: 

	iframe {
		position: absolute;
			 top: 0;
			 left: 0;
			  width: 100%;
			   height: 100%
	}
}