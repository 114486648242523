#pink-drawer-cont {
  position: relative;
  display: flex;
  height: 400px !important;
  width: 90vw;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  #fpsChecker {
    z-index: 999;
    position: absolute;
    color: white;
    background-color: rgba($color: #000000, $alpha: 0.5);
    padding: 50px;
    font-weight: bold;
    outline: 10px solid white;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pink-drawer-canvas-cont {
    z-index: 998;
    position: absolute;
    width: 90vw;
    height: 400px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
