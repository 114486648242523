.soduko-container {
  text-align: center;

  .soduko-grid {
    border: 5px solid black;
    display: grid;
	width: 100%;
    max-width: 455px;
    margin: 10px auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    overflow: hidden;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
	input {
		border: 1px solid black;
		width:100%;
		aspect-ratio: 1 / 1;
		text-align: center;
		font-size: 1rem;
		transition: background-color 0.25s;
		-moz-appearance: textfield;
		&:nth-child(3n) {
			border-right: 5px solid black;
		  }
		  &:nth-child(9n) {
			border-right: none;
		  }
		  &:nth-child(18) ~ input {
			border-bottom: 5px solid black;
		  }
		  &:nth-child(27) ~ input {
			border-bottom: none;
		  }
		  &:nth-child(18) ~ input {
			border-bottom: 5px solid black;
		  }
		  &:nth-child(27) ~ input {
			border-bottom: none;
		  }
		  &:nth-child(45) ~ input {
			border-bottom: 5px solid black;
		  }
		  &:nth-child(54) ~ input {
			border-bottom: none;
		  }
		  &.red {
			color: red;
			font-weight: bold;
		  }

	  }
  }
  .highlight {
    background-color: rgba(0, 0, 0, 0.25);
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
